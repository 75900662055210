<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <q-b-header></q-b-header>
        <v-card color="#f2f3f7">
          <v-card-text v-html="help">
          </v-card-text>
        </v-card>
      </div>
    </div>
    <div v-else>
      <div>
        <q-b-header-mobile></q-b-header-mobile>
        <v-card color="#f2f3f7">
          <v-card-text v-html="help">
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>


</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  name: "QuestionsHelp",
  components: {
    QBHeaderMobile,
    QBHeader,
  },
  data() {
    return {
      screenType: null,
      help: '',
    }
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
  },
  mounted() {
    this.getScreenType()
    window.axios.get('api/exam/help').then((res) => {
      this.help = res.data
    })
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}

.big {
  font-size: 19px;
}

.icon-page-hover:hover {
  color: #39bf88
}

.icon-view-hover:hover {
  color: #f1803f !important;
}
</style>